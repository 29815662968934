import React, { useContext, useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

//sections
import ContactForm from './sections/contact/ContactForm';
import FAQSection from './sections/FAQSection';
import BlogSection from './sections/BlogSection';

const Contact = ({data}) => {
  const mainData = data.allWpPage.nodes[0].contactACF;
  useEffect(() => {
    let unmounted = false;
    gsap.registerPlugin(ScrollTrigger)

    setTimeout(() => {
      if (!unmounted) {
      }
    }, 1000);
     
    return () => {
      unmounted = true;
    }

  }, []);
  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className="page-wrapper contact-page" id="contact-page-wrapper">
        <ContactForm 
            mainTitle={parse(mainData.contactUsTitle)}
            list={mainData.contactUsCta}
        />
        <FAQSection
            mainTitle={parse(mainData.faqSectionTitle)}
            list={mainData.faqSectionList}
            image1x={
              {
                sourceUrl: mainData.faqSectionImage1x.sourceUrl,
                altText: mainData.faqSectionImage1x.altText,
                width: mainData.faqSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.faqSectionImage2x.sourceUrl,
                altText: mainData.faqSectionImage2x.altText,
                width: mainData.faqSectionImage2x.width,
              }
            }
            VideoUrl={mainData.faqSectionVideourl}
            preTitle={parse(mainData.faqSectionPreTitle)}
            mainDescription={parse(mainData.faqSectionDescription)}
        />
        <BlogSection />
      </div>
    </Layout>
  )
}

export default Contact;

export const indexQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "contact"}}) {
    nodes {
        contactACF {
            contactUsTitle
            contactUsCta {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
              title
              description
              ctaButtonText
              ctaButtonUrl
            }
            faqSectionTitle
            faqSectionImage1x {
              altText
              sourceUrl
              width
            }
            faqSectionImage2x {
              altText
              sourceUrl
              width
            }
            faqSectionVideoUrl
            faqSectionPreTitle
            faqSectionDescription
            faqSectionList {
              title
              content
            }
          }
    }
  }
}`

